import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import people from '../../images/people.png'
import Img from 'gatsby-image/withIEPolyfill'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
import tk from '../../images/toolkit.png'

import {
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
} from 'reactstrap'
const axios = require('axios')

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import {
  faChevronCircleRight,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'

class SchoolKitIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      canViewSK: cookies.get('schoolkit') || false,
      school: null,
      email: null,
      name: null,
      success: null,
      message: null,
    }
    this.register = this.register.bind(this)
  }

  register() {
    axios(
      `https://cleanairgm.com/.netlify/functions/mooSchool?email=${this.state.email}&name=${this.state.name}&school=${this.state.school}`
    ).then(response => {
      this.setState({
        success: response.data.success,
        message: response.data.message,
        canViewSK: response.data.success,
      })
      if (response.data.success) {
        cookies.set('schoolkit', true, { path: '/' })
      }
    })
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const header = get(this, 'props.data.contentfulSchools.header')
    const headerMobile = get(this, 'props.data.contentfulSchools.mobileHeader')
    const post = get(this.props, 'data.contentfulBasicPage')
    const game = get(this, 'props.data.contentfulSchools.game')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={'Schools Kit | ' + siteTitle} />
          <div className="page-banner">
            <Img
              fluid={header.fluid}
              className="page-banner__img d-none d-lg-block"
            />
            <Img
              fluid={headerMobile.fluid}
              className="page-banner__img d-block d-lg-none"
            />
            <h1>Schools toolkit</h1>
          </div>
          {this.state.canViewSK && (
            <div>
              <Container className="mt-5 mb-5">
                <Row>
                  <Col sm={12} md={6} className="vc">
                    {post.text && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.text.childMarkdownRemark.html,
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={6} className="vc">
                    <img className="d-block w-100" src={tk} alt="" />
                  </Col>
                </Row>
              </Container>
              <div className="pt-5 pb-5" style={{ backgroundColor: '#F1F4F7' }}>
                <Container>
                  <Row>
                    <Col className="school-pack" sm={12} lg={6}>
                      <h2 className="mb-4">Primary school pack</h2>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon"
                          icon={faCheckCircle}
                        />
                        <div>
                          Campaign plan resources (templates, worksheets,
                          information, case studies…)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon"
                          icon={faCheckCircle}
                        />
                        <div>
                          Lesson plan resources (Questionnaires, badges, fact
                          sheets, signs…)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon"
                          icon={faCheckCircle}
                        />
                        <div>
                          Lesson screens (Powerpoint presentations for lessons)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon"
                          icon={faCheckCircle}
                        />
                        <div>Air quality monitoring guide</div>
                      </div>
                      <div className="mt-5">
                        <a
                          href="https://images.ctfassets.net/tlpgbvy1k6h2/1gZBqdS4Y6HL92oXCmGQSx/63d6b7d4cfeb0a0ad9a94751e978411e/Clean_Air_Schools_Toolkit_Primary_1.pdf"
                          class="btn btn-about btn-about--0 mr-3"
                        >
                          Toolkit Guide
                        </a>
                        <a
                          href="https://downloads.ctfassets.net/tlpgbvy1k6h2/3RYIfV132MCLbi1FquW3mp/afb53d02f2509ad86bbb01f3fb7fad5f/Resources1.zip"
                          class="btn btn-about btn-about--2"
                        >
                          Resources
                        </a>
                      </div>
                    </Col>
                    <Col className="school-pack" sm={12} lg={6}>
                      <h2 className="mb-4">Secondary school pack</h2>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon2"
                          icon={faCheckCircle}
                        />
                        <div>
                          Campaign plan resources (templates, worksheets,
                          information, case studies…)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon2"
                          icon={faCheckCircle}
                        />
                        <div>
                          Lesson plan resources (Questionnaires, badges, fact
                          sheets, signs…)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon2"
                          icon={faCheckCircle}
                        />
                        <div>
                          Lesson screens (Powerpoint presentations for lessons)
                        </div>
                      </div>
                      <div className="school-pack__point">
                        <FontAwesomeIcon
                          className="school-pack__point__icon2"
                          icon={faCheckCircle}
                        />
                        <div>Air quality monitoring guide</div>
                      </div>
                      <div className="mt-5">
                        <a
                          href="https://images.ctfassets.net/tlpgbvy1k6h2/6G2d6ugDWSyTfPUV7hka8G/acc33db14fa3b4928a71ef161f6d1c6b/Clean_Air_Schools_Toolkit_Secondary_1.pdf"
                          class="btn btn-about btn-about--0 mr-3"
                        >
                          Toolkit Guide
                        </a>
                        <a
                          href="https://downloads.ctfassets.net/tlpgbvy1k6h2/7vyxhEkC1XKtVIoaO3LILO/c2d49eedd1fcc51b30a656fb15550ae8/Resources2.zip"
                          class="btn btn-about btn-about--2"
                        >
                          Resources
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* <Container className="mb-5 mt-5 text-center text-lg-left">
                <Row>
                  <Col className="mb-4 mb-md-0 vc" sm={12} lg={{ size: 6 }}>
                    <Img className="br-10" fluid={game.image.fluid} />
                  </Col>

                  <Col sm={12} lg={{ size: 6 }} className="vc">
                    <h2 className="h2-potential mb-3">{game.title}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: game.text.childMarkdownRemark.html,
                      }}
                    ></div>
                    <div>
                      <a
                        className="btn btn-content"
                        href="https://www.cleanairgame.com"
                      >
                        Play now
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container> */}
            </div>
          )}
          {!this.state.canViewSK && (
            <Container className="mt-5 mb-5">
              <h2>Registration</h2>
              <p>
                Please register using the form below to be given instant access
                to downloading school kit.
              </p>
              {this.state.success === false && (
                <Alert color="warning">{this.state.message}</Alert>
              )}
              <Row>
                <Col sm={12} lg={6}>
                  <div className="school-form">
                    <FormGroup>
                      <Label for="name">Contact name</Label>
                      <Input
                        name="name"
                        type="text"
                        autoComplete="name"
                        aria-label="Enter contact name"
                        placeholder="Enter contact name"
                        onChange={t => {
                          this.setState({ name: event.target.value })
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="school">School name</Label>
                      <Input
                        name="school"
                        type="text"
                        autoComplete="school"
                        aria-label="Enter school name"
                        placeholder="Enter school name"
                        onChange={t => {
                          this.setState({ school: event.target.value })
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        name="email"
                        type="text"
                        aria-describedBy="err"
                        autoComplete="email"
                        aria-label="Enter your email address"
                        placeholder="Enter your email address"
                        onChange={t => {
                          this.setState({ email: event.target.value })
                        }}
                      />
                    </FormGroup>
                  </div>
                  <Button onClick={this.register} color="call">
                    Register
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </Layout>
    )
  }
}

export default SchoolKitIndex

export const pageQuery = graphql`
  query SchoolsTKQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSchools {
      title
      header {
        fluid(maxWidth: 1920, maxHeight: 450, quality: 95) {
          ...GatsbyContentfulFluid
        }
      }
      mobileHeader {
        fluid(maxWidth: 1080, maxHeight: 720) {
          ...GatsbyContentfulFluid
        }
      }
    }
    contentfulBasicPage(slug: { eq: "schools-toolkit" }) {
      id
      text {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulSchools {
      game {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670, maxHeight: 400) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
